/*
 * @Author: fan.lion@foxmail.com 
 * @Date: 2019-09-16 19:39:02 
 * @Last Modified by:   fan.lion@foxmail.com 
 * @Last Modified time: 2019-09-16 19:39:02 
 * 
 * 404页
 */
import React from 'react';

import Layout from '../components/Layout';
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
